<template>
	<ZyroFieldText
		:value="stylePropertyValue"
		:label="$attrs.label"
		:bold="false"
		class="style-property"
		:suffix="unit"
		input-type="number"
		maxlength="5"
		v-bind="$attrs"
		@input="setStyleProperty({
			element,
			property,
			value: `${$event.target.value}${unit}`
		})"
	/>
</template>

<script>
import {
	mapState,
	mapMutations,
} from 'vuex';

import {
	extractMobileProperty,
	isMobileProperty,
} from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/controls/utils/getProperty';
import {
	PROPERTY_LETTER_SPACING,
	PROPERTY_LINE_HEIGHT,
} from '@/constants/globalStyles';

const DEFAULT_NORMAL_PROPERTIES = [
	PROPERTY_LETTER_SPACING,
	PROPERTY_LINE_HEIGHT,
];
const DEFAULT_NORMAL_VALUE = 'normal';

export default {
	props: {
		element: {
			type: String,
			required: true,
		},
		property: {
			type: String,
			required: true,
		},
		unit: {
			type: String,
			default: 'px',
		},
	},
	computed: {
		...mapState(['website']),
		stylePropertyValue() {
			const { styles } = this.website;
			const elementPropertyValue = styles[this.element][this.property];
			const elementPropertyValueWithoutMobile = styles[this.element][extractMobileProperty(
				this.property,
			)];

			if (
				isMobileProperty(this.property)
				&& !elementPropertyValue
				&& elementPropertyValueWithoutMobile
			) {
				return elementPropertyValueWithoutMobile.replace(this.unit, '');
			}

			if (
				DEFAULT_NORMAL_PROPERTIES.includes(this.property)
				&& (elementPropertyValue === '0' || elementPropertyValue === DEFAULT_NORMAL_VALUE)
			) {
				this.setStyleProperty({
					element: this.element,
					property: this.property,
					value: DEFAULT_NORMAL_VALUE,
				});

				return '0';
			}

			if (!elementPropertyValue) {
				return '';
			}

			return elementPropertyValue.replace(this.unit, '');
		},
	},
	methods: { ...mapMutations(['setStyleProperty']) },
};
</script>

<style lang="scss" scoped>
.style-property {
	margin: 0;
}
</style>
