<template>
	<div class="color-control">
		<ZyroDropdown
			:options="colorVariables"
			:current.sync="selectedColor"
			:bold="false"
			class="color-control__container"
			label="Color"
			button-size="large"
		>
			<template #suffix>
				<div
					class="color-control__preview"
					:style="{ 'background-color': selectedColor.value }"
				/>
			</template>
		</ZyroDropdown>
	</div>
</template>

<script>
import {
	mapState,
	mapMutations,
} from 'vuex';

import { getProperty } from '@/components/builder-drawers/drawers/partials/globalStylesDrawer/controls/utils/getProperty';
import { capitalizeFirstLetter } from '@/utils/modifyString';

const colorOrder = [
	'Dark',
	'Light',
	'Primary',
	'Primary-light',
	'Primary-dark',
	'Primary-accent',
	'Secondary',
	'Secondary-light',
	'Secondary-dark',
	'Secondary-accent',
	'Success',
	'Warning',
	'Danger',
];

export default {
	props: {
		element: {
			type: String,
			required: true,
		},
		property: {
			type: String,
			required: true,
		},
		state: {
			type: String,
			default: null,
			validator(value) {
				return ['hover'].includes(value);
			},
		},
	},
	computed: {
		...mapState(['website']),
		colorVariables() {
			const mappedColors = Object.entries(this.website.styles.colors)
				.map(([key]) => ({
					title: capitalizeFirstLetter(key),
					value: `var(--colors-${key})`,
				}))
				.sort((a, b) => {
					const aIndex = colorOrder.indexOf(a.title);
					const bIndex = colorOrder.indexOf(b.title);

					// If values are specified in colorOrder, puts them first
					if (aIndex >= 0 && bIndex === -1) return -1;
					if (aIndex === -1 && bIndex >= 0) return 1;
					if (aIndex >= 0 && bIndex >= 0) return aIndex - bIndex;

					// Sorts the rest alphabetically. Titles are unique, comes from object keys
					return a.title < b.title ? -1 : 1;
				});

			return mappedColors;
		},
		selectedColor: {
			get() {
				const currentColor = this.website.styles[this.element][this.propertyWithState];
				const currentColorVariableIndex = this.colorVariables
					.findIndex((colorVariable) => colorVariable.value === currentColor);

				if (currentColorVariableIndex === -1) {
					return { title: 'Select color...' };
				}

				return this.colorVariables[currentColorVariableIndex];
			},
			set(color) {
				this.setStyleProperty({
					element: this.element,
					property: this.propertyWithState,
					value: color.value,
				});
			},
		},
		propertyWithState() {
			return getProperty(this.property, this.state);
		},
	},
	methods: { ...mapMutations(['setStyleProperty']) },
};
</script>

<style lang="scss" scoped>
@import '@/components/builder-drawers/drawers/partials/_forms.scss';

.color-control {
	&__container {
		margin: 0;
	}

	&__preview {
		$size: 37px;

		width: $size;
		height: $size;
		border: 1px solid $grey-200;
		border-radius: $border-radius-small;
	}
}
</style>
